import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { User } from '../../shared/user/model/user.model';
import { UserService } from 'src/app/shared/user/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit, OnDestroy {
  form: FormGroup;
  error: any = null;
  spinnerLoading = false;
  constructor(private fb: FormBuilder, private authService: AuthService, 
    private userService: UserService, private router: Router ) {
    
  }

  ngOnInit() {
    this.form = this.fb.group({
      login : [null, Validators.compose([Validators.required])],
      password : [null, Validators.compose([Validators.required])],
    });
  }
  ngOnDestroy() {
  }

  submit() {
    this.error = null;
    this.spinnerLoading = true;
    this.authService.login(this.form.value).subscribe(
      (res: any) => {
        this.spinnerLoading = false;
        if (res.message === 'error_login') {
          this.error = 'Erreur identifiant';
        } else if (res.message === 'error_password') {
          this.error = 'Erreur mot de passe';
        } else if (res.message === 'success') {
          this.error = null;
            const user = new User(res.login, null, res.token, null);
            const gcmUser = window.localStorage.getItem('gcmUser');
            if (gcmUser) {
              window.localStorage.removeItem('gcmUser');
            }
          window.localStorage.setItem('gcmUser', JSON.stringify(user));
          this.userService.currentUser.next(user);
          this.router.navigate( ['/dashboard'] );
        }
      }, (err) => {
        this.error = "Une erreur s'est produite, réesseyez.";
        this.spinnerLoading = false;
      }
    )
  }

}
