import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/shared/user/user.service';

declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
    { path: '/home', title: 'ACCUEIL',  icon: 'ni-shop text-primary', class: '' },
    { path: '/books', title: 'BIBLIOTHÈQUE',  icon:'ni-books text-red', class: '' },
    { path: '/staff', title: 'STAFF',  icon:'ni-circle-08 text-yellow', class: '' },
    { path: '/galerie', title: 'Vidéos',  icon:'ni-tv-2 text-primary', class: '' },
    { path: '/qcms', title: 'CBN AFRIQUE QCM',  icon:'ni-button-play text-orange', class: '' }
    // { path: '/maps', title: 'Maps',  icon:'ni-pin-3 text-orange', class: '' },
    // { path: '/user-profile', title: 'User profile',  icon:'ni-single-02 text-yellow', class: '' },
    // { path: '/tables', title: 'Tables',  icon:'ni-bullet-list-67 text-red', class: '' },
    // { path: '/login', title: 'Login',  icon:'ni-key-25 text-info', class: '' },
    // { path: '/register', title: 'Register',  icon:'ni-circle-08 text-pink', class: '' }
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  public menuItems: any[];
  public isCollapsed = true;
  public user: any;

  constructor(private router: Router, private userService: UserService ) {
    this.user = JSON.parse(window.localStorage.getItem('gcmUser'));
    console.log(this.user);
  }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
   });
  }

  logout() {
    if (confirm("Continuer la déconnexion ?")) {
      this.userService.currentUser.next(null);
    }
  }
}
