import { Component } from '@angular/core';
import { AuthService } from './shared/auth/auth.service';
import { Router } from '@angular/router';
import { UserService } from './shared/user/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'argon-dashboard-angular';
  constructor(private authService: AuthService, private userService: UserService,
    private router: Router) {
    this.userService.currentUser.subscribe(
      (res) => {
        if (res === null) {
          window.localStorage.removeItem('gcmUser');
          this.router.navigate(['/login']);
        }
      }
    )
  }
}
