import { Routes } from '@angular/router';

import { DashboardComponent } from '../../pages/dashboard/dashboard.component';
import { BooksComponent } from '../../pages/books/books.component';
import { QcmsComponent } from '../../pages/qcms/qcms.component';

export const AdminLayoutRoutes: Routes = [
    { path: 'dashboard', 
      loadChildren: '../../pages/dashboard/dashboard.module#DashboardModule',
    },
    { path: 'books', 
      loadChildren: '../../pages/books/books.module#BooksModule', },
    { path: 'qcms', 
      loadChildren: '../../pages/qcms/qcms.module#QcmsModule', },
    { path: 'staff', 
      loadChildren: '../../pages/staff/staff.module#StaffModule', },
    { path: 'galerie', 
      loadChildren: '../../pages/gallery/gallery.module#GalleryModule', },
];
