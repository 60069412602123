export const GlobalVariable = Object.freeze({
    // local
    // BASE_API_URL: 'http://localhost/cbn',
    // BASE_BOOKS_MEDIA_URL: 'http://localhost/cbn/books/',
    // BASE_QUEST_IMAGES_URL: 'http://localhost/cbn/questions/',
    // online
    BASE_API_URL: 'https://api.portailcbnafrique.org/quizz',
    BASE_BOOKS_MEDIA_URL: 'https://api.portailcbnafrique.org/quizz/books/',
    BASE_QUEST_IMAGES_URL: 'https://api.portailcbnafrique.org/quizz/questions/',
    
    APP_ID: 'CBN_FOUND_ATION_APP',
    
    API_MEDIA_URL: 'http://localhost/sello/medias',
    COUNTRY_PHONE_CODE: '228',
    COUNTRY_PHONE_LENGTH: 8,
    FIREBASE_SENDER_ID: '928745526831'
});
